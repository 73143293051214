/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div :class="{ 'cursor-drag': draggingCard === true }" class="vx-row">
        <div class="vx-col lg:w-3/12 md:w-3/12 sm:w-5/5">
          <vx-card class="mb-base">
            <h3 class="font-bold mb-4">Controls</h3>
            <vue-draggable-container id="wordBank" class="wordBank">
              <h5 class="dragBlock my-4 font-semibold cursor-pointer">Physical</h5>
              <h5 class="dragBlock my-4 font-semibold cursor-pointer">Technical</h5>
              <h5 class="dragBlock my-4 font-semibold cursor-pointer">Technical</h5>
              <h5 class="dragBlock my-4 font-semibold cursor-pointer">Administrative</h5>
              <h5 class="dragBlock my-4 font-semibold cursor-pointer">Administrative</h5>
            </vue-draggable-container>
          </vx-card>
        </div>
        <div class="vx-col lg:w-9/12 md:w-9/12 sm:w-5/5">
          <vx-card class="mb-base">
            <div class="fourth-sim pt-0">
              <div class="vx-row mb-4">
                <div class="vx-col w-6/12 flex items-center">
                  <h2 class="font-bold">Activity</h2>
                </div>
                <div class="vx-col w-6/12 flex items-center">
                  <h2 class="font-bold">Control Type</h2>
                </div>
              </div>
              <div class="vx-row border-white border-2 border-solid rounded p-4">
                <div class="vx-col w-6/12 flex items-center">
                  <img
                    draggable="false"
                    class="mr-4"
                    src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/184766243515924480/policy.png"
                    width="50"
                  />
                  <h4 class="font-semibold">Release a policy requiring the use of cable locks</h4>
                </div>
                <div class="vx-col w-6/12">
                  <vue-draggable-container id="input0" class="answerStandard dropzone dropzoneStandard answer-bg">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row border-white border-2 border-solid rounded p-4 my-4">
                <div class="vx-col w-6/12 flex items-center">
                  <img
                    draggable="false"
                    class="mr-4"
                    src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/184766243515924480/owner.png"
                    width="50"
                  />
                  <h4 class="font-semibold">Use asset management forms to assign hardware to specific individuals</h4>
                </div>
                <div class="vx-col w-6/12">
                  <vue-draggable-container id="input1" class="answerStandard dropzone dropzoneStandard answer-bg">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row border-white border-2 border-solid rounded p-4 my-4">
                <div class="vx-col w-6/12 flex items-center">
                  <img
                    draggable="false"
                    class="mr-4"
                    src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/184766243515924480/cabinet.png"
                    width="50"
                  />

                  <h4 class="font-semibold">Provide dock & lock cabinets for the storage of laptop computers</h4>
                </div>
                <div class="vx-col w-6/12">
                  <vue-draggable-container id="input2" class="answerStandard dropzone dropzoneStandard answer-bg">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row border-white border-2 border-solid rounded p-4 my-4">
                <div class="vx-col w-6/12 flex items-center">
                  <img
                    draggable="false"
                    class="mr-4"
                    src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/184766243515924480/disk.png"
                    width="50"
                  />

                  <h4 class="font-semibold">Implement full disk encryption on all portable devices</h4>
                </div>
                <div class="vx-col w-6/12">
                  <vue-draggable-container id="input3" class="answerStandard dropzone dropzoneStandard answer-bg">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row border-white border-2 border-solid rounded p-4 my-4">
                <div class="vx-col w-6/12 flex items-center">
                  <img
                    draggable="false"
                    class="mr-4"
                    src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/184766243515924480/usb-cable.png"
                    width="50"
                  />

                  <h4 class="font-semibold">Configure port security to improve asset identification and location efforts</h4>
                </div>
                <div class="vx-col w-6/12">
                  <vue-draggable-container id="input4" class="answerStandard dropzone dropzoneStandard answer-bg">
                  </vue-draggable-container>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          //this.drag_error()
          x.cancel();
        }
      }
    },
    markSimulation() {
      let totalScore = 0;

      if (document.getElementById('input0').textContent === 'Administrative') {
        document.getElementById('input0').style.borderColor = '#28C76F';
        document.getElementById('input0').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input1').textContent === 'Administrative') {
        document.getElementById('input1').style.borderColor = '#28C76F';
        document.getElementById('input1').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input2').textContent === 'Physical') {
        document.getElementById('input2').style.borderColor = '#28C76F';
        document.getElementById('input2').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input3').textContent === 'Technical') {
        document.getElementById('input3').style.borderColor = '#28C76F';
        document.getElementById('input3').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input3').style.borderColor = '#fd4445';
        document.getElementById('input3').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input4').textContent === 'Technical') {
        document.getElementById('input4').style.borderColor = '#28C76F';
        document.getElementById('input4').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input4').style.borderColor = '#fd4445';
        document.getElementById('input4').style.backgroundColor = '#fd4445';
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    draggable,
    Prism,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
